<template>
  <v-form ref="form">
    <v-row>
      <v-col
        cols="12"
        md="3"
      >
        <label for="text">Text</label>
      </v-col>

      <v-col
        cols="12"
        md="9"
      >
        <v-textarea
          id="text"
          v-model="text"
          type="textarea"
          outlined
          dense
          hide-details="auto"
          placeholder="Enter message"
          :rules="[isNotEmptyString]"
        ></v-textarea>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { required, isNotEmptyString } from '@core/utils/validation'
import { mdiAlertCircleOutline, mdiCalendar, mdiCheck } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  props: {
    message: {
      type: Object,
      default: () => {},
    },
  },

  setup({ message }) {
    const form = ref(null)

    const validate = () => form.value.validate()
    const reset = () => form.value.reset()

    const text = ref(message.text || '')

    const submit = () => ({
      id: message.id,
      text: text.value,
    })

    return {
      form,
      text,

      validate,
      reset,
      submit,
      required,
      isNotEmptyString,
      icons: {
        mdiCheck,
        mdiAlertCircleOutline,
        mdiCalendar,
      },
    }
  },
}
</script>
