<template>
  <v-text-field
    v-model="localValue"
    label="Search"
    :prepend-inner-icon="icons.mdiMagnify"
    clearable
    hide-details
    solo-inverted
    rounded
    class="search pr-0"
  >
    <template
      v-if="!hideFilter"
      v-slot:append
    >
      <v-btn
        text
        rounded
        large
        :color="isHighlighted ? 'primary' : 'secondary'"
        @mousedown.stop.prevent
        @click.prevent.stop="toggleFilter"
      >
        <v-icon left>
          {{ icons.mdiFilter }}
        </v-icon>
        <span
          class="text-capitalize"
        >Filter</span>
      </v-btn>
    </template>
  </v-text-field>
</template>

<script>
import {
  mdiMagnify,
  mdiFilter,
} from '@mdi/js'

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    showFilters: Boolean,
    checkFilterToggle: {
      type: Function,
      default: null,
    },
    hideFilter: Boolean,
  },

  data() {
    return {
      icons: {
        mdiMagnify,
        mdiFilter,
      },
    }
  },

  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    localShowFilters: {
      get() {
        return this.showFilters
      },
      set(value) {
        this.$emit('update:showFilters', value)
      },
    },
    isHighlighted() {
      return this.localShowFilters || this.checkFilterToggle?.()
    },
  },
  methods: {
    toggleFilter() {
      this.localShowFilters = !this.localShowFilters
    },
  },
}
</script>

<style lang="scss" scoped>
.search{
  max-width: 400px;
  min-width: 40%;
  &::v-deep .v-input__slot {
    padding-right: 0;
  }
}
</style>
