import TelegramApi from '@core/api/telegram'

export default {
  state: {
    accounts: [],
  },
  getters: {},
  mutations: {
    SET_ACCOUNTS(state, accounts) {
      state.accounts = accounts
    },

    EDIT_ACCOUNT(state, value) {
      const editedAccountIdx = state.accounts.findIndex(p => p.id === value.id)

      if (~editedAccountIdx) {
        state.accounts.splice(editedAccountIdx, 1, value)

        return value
      }

      return null
    },

    REMOVE_ACCOUNT(state, account) {
      const deletedAccountIdx = state.accounts.findIndex(p => p.id === account.id)

      //
      if (~deletedAccountIdx) {
        return state.accounts.splice(deletedAccountIdx, 1)
      }

      return null
    },
  },
  actions: {
    fetchAccounts({ commit }, params) {
      return TelegramApi.Accounts.fetchAccounts(params)
        .then(response => {
          commit('SET_ACCOUNTS', response.data.items)

          return response
        })
    },

    addAccount(ctx, { account }) {
      return TelegramApi.Accounts.addAccount({ account })
    },

    updateAccount(ctx, { account }) {
      return TelegramApi.Accounts.updateAccount({ account })
    },

    removeAccount(ctx, account) {
      return TelegramApi.Accounts.removeAccount(account)
    },
  },
}
