import action from './action'
import group from './group'
import message from './message'
import account from './account'
import task from './task'

export const TELEGRAM_MODULE_NAME = 'app-telegram'

export default {
  namespaced: true,
  modules: {
    group, message, account, task, action,
  },
}
