import TelegramApi from '@core/api/telegram'

export default {
  state: {
    messages: [],
  },
  getters: {},
  mutations: {
    SET_MESSAGES(state, messages) {
      state.messages = messages
    },

    EDIT_MESSAGE(state, value) {
      const editedMessageIdx = state.messages.findIndex(p => p.id === value.id)

      if (~editedMessageIdx) {
        state.messages.splice(editedMessageIdx, 1, value)

        return value
      }

      return null
    },

    REMOVE_MESSAGE(state, message) {
      const deletedMessageIdx = state.messages.findIndex(p => p.id === message.id)

      //
      if (~deletedMessageIdx) {
        return state.messages.splice(deletedMessageIdx, 1)
      }

      return null
    },
  },
  actions: {
    fetchMessages({ commit }, params) {
      return TelegramApi.Messages.fetchMessages(params)
        .then(response => {
          commit('SET_MESSAGES', response.data.items)

          return response
        })
    },

    addMessage(ctx, { message }) {
      return TelegramApi.Messages.addMessage({ message })
    },

    updateMessage(ctx, { message }) {
      return TelegramApi.Messages.updateMessage({ message })
    },

    removeMessage(ctx, message) {
      return TelegramApi.Messages.removeMessage(message)
    },
  },
}
