import TelegramApi from '@core/api/telegram'

export default {
  state: {
    tasks: [],
  },
  getters: {},
  mutations: {
    SET_TASKS(state, tasks) {
      state.tasks = tasks
    },

    EDIT_TASK(state, value) {
      const editedTaskIdx = state.tasks.findIndex(p => p.id === value.id)

      if (~editedTaskIdx) {
        state.tasks.splice(editedTaskIdx, 1, value)

        return value
      }

      return null
    },

    REMOVE_TASK(state, account) {
      const deletedTaskIdx = state.tasks.findIndex(p => p.id === account.id)

      //
      if (~deletedTaskIdx) {
        return state.tasks.splice(deletedTaskIdx, 1)
      }

      return null
    },
  },
  actions: {
    fetchTasks({ commit }, params) {
      return TelegramApi.Tasks.fetchTasks(params)
        .then(response => {
          commit('SET_TASKS', response.data.items)

          return response
        })
    },

    addTask(ctx, { task }) {
      return TelegramApi.Tasks.addTask({ task })
    },

    updateTask(ctx, { task }) {
      return TelegramApi.Tasks.updateTask({ task })
    },

    removeTask(ctx, task) {
      return TelegramApi.Tasks.removeTask(task)
    },
  },
}
