import axios from '@axios'

export default {
  Groups: {
    fetchGroups: params => axios
      .get('/telegram/groups', {
        params,
      }),

    addGroup: ({ group }) => axios
      .post('/telegram/group', group),

    updateGroup: ({ group }) => axios
      .patch(`/telegram/group/${group.id}`, group),

    removeGroup: group => axios
      .delete(`/telegram/group/${group.id}`),
  },
  Messages: {
    fetchMessages: params => axios
      .get('/telegram/messages', {
        params,
      }),

    addMessage: ({ message }) => axios
      .post('/telegram/message', message),

    updateMessage: ({ message }) => axios
      .patch(`/telegram/message/${message.id}`, message),

    removeMessage: message => axios
      .delete(`/telegram/message/${message.id}`),
  },
  Accounts: {
    fetchAccounts: params => axios
      .get('/telegram/account', {
        params,
      }),

    addAccount: ({ account }) => axios
      .post('/telegram/account', account),

    updateAccount: ({ account }) => axios
      .patch(`/telegram/account/${account.id}`, account),

    login: account => axios
      .get(`/telegram/account/${account.id}/login`),

    // TODO: update URL
    verifiedCode: data => axios
      .post('/telegram/input_code', data),
    removeAccount: account => axios
      .delete(`/telegram/account/${account.id}`),
  },
  Tasks: {
    fetchTasks: params => axios
      .get('/telegram/tasks', {
        params,
      }),

    addTask: ({ task }) => axios
      .post('/telegram/task', task),

    updateTask: ({ task }) => axios
      .patch(`/telegram/task/${task.id}`, task),

    removeTask: task => axios
      .delete(`/telegram/task/${task.id}`),
  },
  Action: {
    fetchActions: params => axios
      .get('/telegram/actions', {
        params,
      }),
    fetchAction: id => axios
      .get(`/telegram/actions/${id}`, { params: { limit: 20, page: 1 } }),

    addAction: ({ action }) => axios
      .post('/telegram/actions', action),

    updateAction: ({ action }) => axios
      .patch(`/telegram/actions/${action.id}`, action),

    removeAction: account => axios
      .delete(`/telegram/actions/${account.id}`),
  },
}
