<template>
  <v-dialog
    v-model="dialog"
    v-bind="$attrs"
    @keydown.esc="cancel"
  >
    <slot
      :agree="agree"
      :cancel="cancel"
    >
    </slot>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
    }
  },

  methods: {
    open() {
      this.dialog = true

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      this.resolve(true)
      this.dialog = false
    },
    cancel() {
      this.reject(false)
      this.dialog = false
    },
  },
}
</script>
