<template>
  <v-layout
    d-flex
    align-center
  >
    <v-checkbox
      v-model="localValue"
      hide-details
      class="ma-0"
      :color="color"
      :indeterminate="value.indeterminate"
    />
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :color="color"
          plain
          depressed
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>{{ icons.mdiChevronDown }}</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in localOptions"
          :key="index"
          @click="selectOption(item.value)"
        >
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- <v-select
      :color="color"
      :item-color="color"
      :items="localOptions"
      @input="selectOption"
    >
      <template v-slot:selection>
      </template>
    </v-select> -->
  </v-layout>
</template>

<script>
import { mdiChevronDown } from '@mdi/js'

export default {
  props: {
    isAll: Boolean,
    value: {
      type: Object,
      default: () => ({}),
    },
    page: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      icons: {
        mdiChevronDown,
      },
    }
  },
  computed: {
    color() {
      // eslint-disable-next-line no-nested-ternary
      return this.localValue
        ? this.localIsAll
          ? 'accent'
          : 'primary'
        : 'default'
    },
    localIsAll: {
      get() {
        return this.isAll
      },
      set(value) {
        this.$emit('update:isAll', value)
      },
    },
    localValue: {
      get() {
        return this.value.value
      },
      set(value) {
        this.localIsAll = false
        this.$emit('input', value)
      },
    },
    localOptions() {
      const options = [{
        text: `Select page (${this.page})`,
        value: 'page',
      }, {
        text: `Select all (${this.total})`,
        value: 'all',
      }]

      if (this.value.value || this.value.indeterminate) {
        options.unshift({
          text: 'Deselect',
          value: 'deselect',
        })
      }

      return options
    },
  },
  methods: {
    selectOption(value) {
      this.localIsAll = false
      switch (value) {
        case ('all'):
          this.localIsAll = true
          this.$emit('input', { value: true })
          break

        case ('page'):
          this.$emit('input', { value: true })
          break

        case ('deselect'):
        default:
          this.$emit('input', false)
      }
    },
  },
}
</script>
