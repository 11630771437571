<template>
  <v-form ref="form">
    <v-row>
      <v-col
        cols="12"
        md="3"
      >
        <label for="text">Text</label>
      </v-col>

      <v-col
        cols="12"
        md="9"
      >
        <v-textarea
          id="text"
          v-model="text"
          type="textarea"
          outlined
          dense
          hide-details="auto"
          placeholder="Enter message"
          :disabled="loading"
          :rules="[isNotEmptyString]"
        ></v-textarea>
      </v-col>
    </v-row>

    <v-col
      offset-md="3"
      cols="12"
    >
      <v-btn
        color="primary"
        :loading="loading"
        @click="submit"
      >
        Create
      </v-btn>
      <v-btn
        type="reset"
        class="mx-2"
        :disabled="loading"
        outlined
        @click="reset"
      >
        Reset
      </v-btn>
    </v-col>
  </v-form>
</template>

<script>
import { ref } from '@vue/composition-api'

import { required, isNotEmptyString } from '@core/utils/validation'

export default {
  props: {
    loading: Boolean,
  },
  setup(props, { emit }) {
    const form = ref(null)
    const validate = () => form.value.validate()

    const reset = () => {
      form.value.reset()
    }
    const text = ref('')

    const submit = async () => {
      if (validate()) {
        const data = {
          text: text.value,
        }
        emit('submit', data)
      }
    }

    return {
      form,
      text,

      validate,
      reset,
      required,
      isNotEmptyString,
      submit,
    }
  },
}
</script>
