<template>
  <v-card>
    <v-card-title>
      <v-btn
        v-show="selectedMessages.length"
        class="mr-6"
        icon
        color="error"
        @click="tryDeleteManyMessages(selectedMessages)"
      >
        <v-icon>{{ icons.mdiDeleteForever }}</v-icon>
      </v-btn>
      <TableSearch
        v-model="tableOptions.search"
        hide-filter
      />
      <v-spacer />
      <AddNewMessage
        @submitted="tryFetchMessages"
      />
    </v-card-title>
    <v-card-text>
      <v-data-table
        v-model="selectedMessages"
        hide-default-footer
        :mobile-breakpoint="0"
        item-key="id"
        loading-text="Loading messages ..."
        :loading="loading"
        :server-items-length="total"
        :items="messages"
        :headers="messageHeaders"
        :options.sync="tableOptions"
        :footer-props="{
          'items-per-page-options': pages,
          'show-first-last-page': true,
          'show-current-page': true,
        }"
      >
        <template v-slot:header.status="{ header }">
          <span>{{ header.text }}</span>
        </template>
        <!-- SELECT ROW-->
        <template v-slot:header.data-table-select="{ props, on }">
          <CustomSelectRows
            :value="props"
            :page="tableOptions.page"
            :total="total"
            v-on="on"
          />
        </template>
        <template v-slot:item.value="{ item }">
          <v-skeleton-loader
            :loading="item.loading"
            type="table-cell"
          >
            {{ item.value }}
          </v-skeleton-loader>
        </template>
        <template v-slot:item.action="{ item }">
          <TableActions
            :loading="item.loading"
            @edit="openMessageEditModal(item)"
            @delete="tryDeleteMessage(item.id)"
          />
        </template>
        <template v-slot:footer="{ props, on }">
          <TableFooter
            v-bind="props"
            v-on="on"
          />
        </template>
      </v-data-table>
    </v-card-text>

    <MessageEditModal
      ref="editMessageModal"
      @update="tryUpdateMessage"
    />

    <ConfirmDialog
      ref="deleteMessageConfirm"
      max-width="400"
    >
      <template v-slot="{agree, cancel}">
        <v-card>
          <v-card-title>Delete message</v-card-title>
          <v-card-text>
            Do you really want to delete message
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="error"
              @click="agree"
            >
              <v-icon>{{ icons.mdiDeleteForever }}</v-icon>
              Delete
            </v-btn>
            <v-btn
              @click="cancel"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </ConfirmDialog>
  </v-card>
</template>

<script>
import {
  mdiDeleteForever,
  mdiDotsVertical,
  mdiPencil,
  mdiMagnify,
} from '@mdi/js'
import pick from 'lodash/pick'
import { mapActions, mapMutations, mapState } from 'vuex'
import store from '@/store'
import telegramStoreModule, { TELEGRAM_MODULE_NAME } from '@/store/modules/telegramStoreModule'

import CustomSelectRows from '@/components/CustomSelectRows.vue'
import AddNewMessage from '@/components/telegram/message/AddNew.vue'
import MessageEditModal from '@/components/telegram/message/EditModal.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import TableActions from '@/components/TableActions.vue'
import TableFooter from '@/components/TableFooter.vue'
import TableSearch from '@/components/TableSearch.vue'

export default {
  components: {
    CustomSelectRows,
    AddNewMessage,
    MessageEditModal,
    ConfirmDialog,
    TableActions,
    TableFooter,
    TableSearch,
  },
  data() {
    return {
      total: 50,
      loading: true,
      tableOptions: {
        search: this.$route.query.search || '',
        sortBy: this.$route.query.sortBy ? [this.$route.query.sortBy] : [],
        sortDesc: +this.$route.query.sortDesc ? [Boolean(+this.$route.query.sortDesc)] : [],
        page: +this.$route.query.page ? +this.$route.query.page : 1,
        itemsPerPage: +this.$route.query.limit ? +this.$route.query.limit : localStorage.getItem('itemsPerPage') || 50,
      },
      search: '',
      selectedMessages: [],
      pages: [50, 100, 200],
      icons: {
        mdiDotsVertical,
        mdiDeleteForever,
        mdiPencil,
        mdiMagnify,
      },
      messageHeaders: [
        {
          text: 'Text',
          value: 'text',
          sortable: true,
          filterable: true,
          align: 'start',
          cellClass: 'py-2',
        },
        {
          align: 'end',
          value: 'action',
          sortable: false,
        },
      ],
    }
  },

  computed: {
    ...mapState({
      messages(state) {
        return state[TELEGRAM_MODULE_NAME]?.message.messages
      },
    }),
    mappedOptions() {
      return this.$route.query
    },
  },

  watch: {
    total() {
      this.tableOptions.page = 1
    },
    tableOptions: {
      handler(value) {
        const query = pick(value, ['sortBy', 'page', 'search', 'status'])

        query.limit = value.itemsPerPage

        if (value.sortBy?.length) {
          [query.sortBy] = value.sortBy
        }

        if (value.sortDesc?.length) {
          [query.sortDesc] = value.sortDesc
        }

        Object.keys(query).forEach(key => {
          if (!query[key] && key !== 'sortDesc') {
            delete query[key]
          }
        })

        this.tryFetchMessages(query)
        this.$router.replace({ query }).catch(() => {})
      },
      deep: true,
    },
  },

  mounted() {
    // Register module
    if (!store.hasModule(TELEGRAM_MODULE_NAME)) {
      store.registerModule(TELEGRAM_MODULE_NAME, telegramStoreModule)
    }
  },

  methods: {
    ...mapActions({
      fetchMessages(dispatch, payload) {
        return dispatch(`${TELEGRAM_MODULE_NAME}/fetchMessages`, payload)
      },
      updateMessage(dispatch, payload) {
        return dispatch(`${TELEGRAM_MODULE_NAME}/updateMessage`, payload)
      },
      removeMessage(dispatch, payload) {
        return dispatch(`${TELEGRAM_MODULE_NAME}/removeMessage`, payload)
      },
    }),

    ...mapMutations({
      commitSetMessages(commit, payload) {
        return commit(`${TELEGRAM_MODULE_NAME}/SET_MESSAGES `, payload)
      },
      commitEditMessage(commit, payload) {
        return commit(`${TELEGRAM_MODULE_NAME}/EDIT_MESSAGE`, payload)
      },
      commitDeleteMessage(commit, payload) {
        return commit(`${TELEGRAM_MODULE_NAME}/REMOVE_MESSAGE`, payload)
      },
    }),

    async tryFetchMessages(params = this.mappedOptions) {
      this.loading = true
      const { data } = await this.fetchMessages(params)
      this.total = data.total

      this.loading = false
    },

    async tryDeleteMessage(id) {
      this.$refs.deleteMessageConfirm.open().then(() => {
        this.commitEditMessage({ id, loading: true })
        this.removeMessage({ id }).then(() => {
          this.commitDeleteMessage({ id })
          this.$message.success('Message deleted successfully')
        }).catch(err => this.$message.error(err))
      })
    },

    async tryUpdateMessage(value) {
      this.commitEditMessage({ ...value, loading: true })
      const { data } = await this.updateMessage({ message: value })
      this.commitEditMessage(data)
    },

    openMessageEditModal(message) {
      this.$refs.editMessageModal.open(message)
    },
  },
}
</script>
