import TelegramApi from '@core/api/telegram'

export default {
  state: {
    groups: [],
  },
  getters: {},
  mutations: {
    SET_GROUPS(state, groups) {
      state.groups = groups
    },

    EDIT_GROUP(state, value) {
      const editedGroupIdx = state.groups.findIndex(p => p.id === value.id)

      if (~editedGroupIdx) {
        state.groups.splice(editedGroupIdx, 1, value)

        return value
      }

      return null
    },

    REMOVE_GROUP(state, group) {
      const deletedGroupIdx = state.groups.findIndex(p => p.id === group.id)

      if (~deletedGroupIdx) {
        return state.groups.splice(deletedGroupIdx, 1)
      }

      return null
    },
  },
  actions: {
    fetchGroups({ commit }, params) {
      return TelegramApi.Groups.fetchGroups(params)
        .then(response => {
          commit('SET_GROUPS', response.data.items)

          return response
        })
    },

    addGroup(ctx, { group }) {
      return TelegramApi.Groups.addGroup({ group })
    },

    updateGroup(ctx, { group }) {
      return TelegramApi.Groups.updateGroup({ group })
    },

    removeGroup(ctx, group) {
      return TelegramApi.Groups.removeGroup(group)
    },
  },
}
